import { useContext } from "react";
import cn from "classnames";

import TableContext from "../TableContext";

import "./TableHead.scss";
import { checkAccountInsightProvider } from "../../../utils/checkAccountInsightProvider";

import Svg from "../../Svg";

const TableHead = ({ sticky, textColor }) => {
  const [{ columns, sort }, setSort] = useContext(TableContext);

  const handleSetSort = (columnId) => {
    setSort((state) => {
      if (state.column === columnId) {
        return {
          ...state,
          direction: state.direction === "desc" ? "asc" : "desc",
        };
      }
      return {
        ...state,
        column: columnId,
      };
    });
  };

  const tableHeadings = columns.map((column) => {
    if (column.group) return false;
    if (column.hiddenColumn) return false;
    if ("insight_provider" in column && !checkAccountInsightProvider(column.insight_provider)) {
        return false;
    }
    const sortButton =
      sort.column === column.id ? (
        <span
          className={cn("TableHead-sort", {
            "TableHead-sort--asc": sort.direction === "asc",
            "TableHead-sort--desc": sort.direction === "desc",
          })}
        >
          <Svg w="6" h="24" i="arrowBottom" />
        </span>
      ) : (
        false
      );

    const style = {
      width: column.width || false,
      color: textColor || ""
    };

    return (
      <th
        key={column.id}
        className={cn("TableHead-cell", {
          "TableHead-cell--sticky": sticky,
          "TableHead-cell--sticky-no-color": true,
          "TableHead-cell--sortable": column.sortable,
          [`TableHead-cell--${column.labelAlign}`]: column.labelAlign,
        })}
        onClick={() => column.sortable && handleSetSort(column.id)}
        style={style}
      >
        {column.label}
        {sortButton}
      </th>
    );
  });

  return (
    <thead className="TableHead">
      <tr>{tableHeadings}</tr>
    </thead>
  );
};

export default TableHead;
