export default [
  {
    type: "textfield",
    label: "Dealership Name (required):",
    id: "signatureDealershipName",
    name: "signatureDealershipName",
    key: "DealershipName",
    isWide: true,
  },
  {
    type: "textfield",
    label: "AI Assistant’s First and Last Name (required):",
    id: "signatureName",
    name: "signatureName",
    key: "FullName",
  },
  {
    type: "textfield",
    label: "AI Assistant’s Job Title (optional):",
    id: "signaturePosition",
    name: "signaturePosition",
    key: "Position",
  },
  {
    type: "textarea",
    label: "Sales Phone number (required):",
    id: "signaturePhonePrimary",
    name: "signaturePhonePrimary",
    key: "MainPhone",
    isWide: true,
  },
  {
    type: "textarea",
    label: "Service Phone number (optional):",
    id: "signaturePhoneSecondary",
    name: "signaturePhoneSecondary",
    key: "SecondaryPhone",
    isWide: true,
  },
  {
    type: "textarea",
    label: "Parts Phone number (optional):",
    id: "signaturePhoneParts",
    name: "signaturePhoneParts",
    key: "PartsPhone",
    isWide: true,
  },
  {
    type: "textarea",
    label: "Website URL (required):",
    id: "signatureWebsite",
    name: "signatureWebsite",
    key: "WebsiteUrl",
  },
  {
    type: "textarea",
    label: "Address (required):",
    id: "signatureAddress",
    name: "signatureAddress",
    key: "Address",
  },
];
