import Layout from "../../components/Layout/Layout";
import DateRangePicker from "../../components/DateRangePicker/DateRangePicker";
import ProductSelector from "../../components/ProductSelector/ProductSelector";
import "./index.scss";
import DashboardDealerChat from "../../components/DashboardDealerChat/DashboardDealerChat";
import useIsCdk from "../../hook/useIsCdk";



const PageDashboardDealerChat = () => {
  const isCDK = useIsCdk();

  const widgets = {
    primary: [
      <DateRangePicker type="date" key="WidgetDateRange" label="Date Filter" />,
    ],
  };
  if (!isCDK) {
    widgets.secondary = [<ProductSelector key="WidgetProductSelector" />]
  }

  let content = <DashboardDealerChat />;
  return <Layout content={content} widgets={widgets} />;
};

export default PageDashboardDealerChat;
