import FormElement from "../../../../FormElement/FormElement";
import "./AppointmentHoursForm.scss";

const options = [
    {id: 0, label: '0'},
    {id: 10, label: '10'},
    {id: 15, label: '15'},
    {id: 20, label: '20'},
    {id: 30, label: '30'},
    {id: 45, label: '45'},
    {id: 60, label: '60'},
    {id: 75, label: '75'},
    {id: 90, label: '90'},
    {id: 120, label: '120'},
];

const AppointmentHoursForm = ({ appointmentHours, setAppointmentHours, setChangeStatus }) => {

    const selectChangeHandler = (key, value) => {
        setAppointmentHours({
            ...appointmentHours,
            [key]: value
        })
        setChangeStatus(true);
    }

    return (
        <>
            <div className="appointments_box">
                <p>Set appointments</p>
                <div className="selectBox">
                    <FormElement
                        type="select"
                        label={'Time Period'}
                        defaultValue={ appointmentHours?.minutesAfterOpening || {id: 60, label: '60'}}
                        values={options}
                        onChange={(value) => selectChangeHandler("minutesAfterOpening", value)}
                    />
                </div>
                <p>minutes after opening</p>
            </div>
            <div className="appointments_box">
                <p>Do not set appointments</p>
                <div className="selectBox">
                    <FormElement
                        type="select"
                        label={'Time Period'}
                        defaultValue={appointmentHours?.minutesBeforeClosing || {id: 60, label: '60'}}
                        values={options}
                        onChange={(value) => selectChangeHandler("minutesBeforeClosing", value)}
                    />
                </div>
                <p>minutes before closing</p>
            </div>
        </>
    )
}

export default AppointmentHoursForm;