import Layout from "../../components/Layout/Layout";
import DateRangePicker from "../../components/DateRangePicker/DateRangePicker";
import "./index.scss";
import ProductSelector from "../../components/ProductSelector/ProductSelector";
import DashboardFI from "../../components/DashboardFI/DashboardFI";
import useIsCdk from "../../hook/useIsCdk";


const PageDashboardFI = () => {
  const isCDK = useIsCdk();

  const widgets = {
    primary: [
      <DateRangePicker type="date" key="WidgetDateRange" label="Date Filter" />,
    ],
  };
  if (!isCDK) {
    widgets.secondary = [<ProductSelector key="WidgetProductSelector" />]
  }

  let content = <DashboardFI />;

  return <Layout content={content} widgets={widgets} />;
};

export default PageDashboardFI;
