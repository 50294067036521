import { Auth0Provider } from "@auth0/auth0-react";
import React from "react";
import { useHistory } from "react-router-dom";
import useIsCdk from "../../hook/useIsCdk";

export const Auth0ProviderWithNavigate = ({ children }) => {
  const history = useHistory();
  const isCDK = useIsCdk();
  const isCDKGlobal = useIsCdk(true);

  const domain = process.env["REACT_APP_AUTH0_DOMAIN"];
  const clientId = process.env["REACT_APP_AUTH0_CLIENT_ID"];
  const audience = process.env["REACT_APP_AUTH0_AUDIENCE"];
  const crmaivaConnection = process.env["REACT_APP_AUTH0_CONNECTION"];
  const crmaivaGlobalConnection = process.env["REACT_APP_AUTH0_CONNECTION_GLOBAL"];

  const redirectUri = window.location.origin;

  const authorizationParams = {
    redirect_uri: redirectUri,
    audience: audience,
  };

  if (isCDK && window.location.hostname !== 'impel-test.crmaiva.ai') {
    authorizationParams.connection = crmaivaConnection
  }
  if (isCDKGlobal && window.location.hostname !== 'cdk-global-test.crmaiva.ai') {
    authorizationParams.connection = crmaivaGlobalConnection
  }

  const onRedirectCallback = async (appState) => {
    history?.push(appState?.returnTo || window.location.pathname);
  };

  if (!(domain && clientId && redirectUri)) {
    return null;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={authorizationParams}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens
      cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  );
};
