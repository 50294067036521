import { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";

import QuestionMark from "../Tile/QuestionMark/QuestionMark";
import WidgetBreadcrumbs from "../WidgetBreadcrumbs/WidgetBreadcrumbs";
import FormElement from "../FormElement/FormElement";
import Svg from "../Svg";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

import "./UserManagementForm.scss";
import FormTextfield from "../FormElement/FormTextfield";
import DealershipsTransferList from "./DealershipsTransferList";
import cn from "classnames";
import roles from "./roles";
import { Snackbar } from "@mui/material";
import SnackbarMessage from "../SnackbarMessage/SnackbarMessage";
import useData from "../../hook/useData";
import { useDispatch, useSelector } from "react-redux";
import { chooseUserManagementSnackbarState } from "../../store/actions/userManagementActions";
import { useAuth0 } from "@auth0/auth0-react";
import req from "../../utils/request";
import _ from "lodash";
import Loader from "../../components/Loader";
import { validateEmail } from "../../utils/fieldValidators"
import DOMPurify from "dompurify";
import useIsCdk from "../../hook/useIsCdk";

const UserManagementForm = () => {
  const unfilledFieldsMessage = "Please fill in all the fields to continue";
  const noSelectedDealershipsMessage = "Please select Dealership to continue";
  const savingInProgressMessage = "Saving in progress, please wait";
  const wrongEmailMessage = "Email format is not correct";

  const auth = useSelector((state) => state.user.auth.data);
  const { user: auth_user } = useAuth0();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { action, userId:userIdParam, retailerName } = useParams();
  const isEdit = action?.toLowerCase() === "edit";


  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/user-management">
      Manage Users
    </Link>,
    <Typography key="2" color="text.primary">
      {isEdit ? "Edit" : "Add"} User
    </Typography>,
  ];

  const isCDK = useIsCdk();

  const [users, setUsers] = useState([
    {
      name: "",
      lastName: "",
      email: "",
      role: "ADMIN",
    },
  ]);
  const [validationErrors, setValidationErrors] = useState({});
  const [selectedDealerships, setSelectedDealerships] = useState([]);
  const [alertState, setAlertState] = useState({
    open: false,
    message: unfilledFieldsMessage,
    severity: "error",
  });
  const [dealershipsSearch, setDealershipsSearch] = useState("");
  const [requestEndpoint, setRequestEndpoint] = useState("");
  const [requestOptions, setRequestOptions] = useState({});
  const [isSelectDisabled, setIsSelectDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [filteredDealerships, setFilteredDealerships] = useState([]);

  const {
    data: apiResponseData,
    status,
    execute: executeApiCall,
  } = useData(
    requestEndpoint,
    requestOptions,
    [requestOptions, requestEndpoint],
    false
  );

  const get_user_id_from_query = () => {
    // return new URLSearchParams(location.search).get("user_id");
    return userIdParam
  };

  const getRetailerName = () => {
    if(isCDK || new URLSearchParams(location.search).get("cdk")) {
      return 'cdk_global'
    }

    return retailerName
  }

  const sanitizeInput = (dirty) => {
    const profile = {
      ALLOWED_TAGS: [],
      ALLOWED_ATTR: []
    }
    const clean = DOMPurify.sanitize(dirty, profile);
    return clean;
  };

  const handleAddUser = () => {
    setUsers([
      ...users,
      {
        name: "",
        lastName: "",
        email: "",
        role: "ADMIN",
      },
    ]);
  };

  const handleRemoveUser = (user) => {
    setUsers(users.filter((item) => item !== user));
  };

  const validateForm = () => {
    const errors = {};
    users.map((user, index) => {
      if (user.name === "") {
        errors[`name-${index}`] = unfilledFieldsMessage;
      }
      if (user.lastName === "") {
        errors[`last-name-${index}`] = unfilledFieldsMessage;
      }
      if (user.email === "") {
        errors[`email-${index}`] = unfilledFieldsMessage;
      } else if (!validateEmail(user.email)) {
        errors[`email-${index}`] = wrongEmailMessage;
      }
      if (!["ADMIN", "REGULAR"].includes(user.role)) {
        errors[`role-${index}`] = unfilledFieldsMessage;
      }
    });
    if (selectedDealerships.length === 0) {
      errors["dealerships"] = noSelectedDealershipsMessage;
    }

    setValidationErrors(errors);
    return JSON.stringify(errors) === "{}";
  };

  const handleSubmit = () => {
    const isFormValid = validateForm();

    if (isFormValid) {
      const options = {
        dealer_stores: selectedDealerships,
        retailer_name: getRetailerName(),
      };

      if (isEdit) {
        const user_id = get_user_id_from_query();

        setRequestEndpoint("updateUser");
        options["role"] = users[0].role;
        options["user_id"] = user_id;
      } else {
        setRequestEndpoint("inviteUsers");
        options["user_info"] = users.map((value) => ({
          first_name: sanitizeInput(value.name),
          last_name: sanitizeInput(value.lastName),
          email: sanitizeInput(value.email),
          role: value.role,
        }));
        options["invited_by"] = {
          user_id: auth_user.sub,
          fullname: sanitizeInput(auth_user.given_name) || sanitizeInput(auth_user.name),
        };
      }
      setRequestOptions(options);
    }
  };

  useEffect(async () => {
    if (isEdit) {
      setIsLoading(true);
      const user_id = get_user_id_from_query();

      const result = await req(
        "queryUsers",
        {
          user_id: userIdParam,
          retailer_name:getRetailerName()

        },
        auth?.access_token
      );
      if ([200, 201].includes(result.status)) {
        const user_data = result.data?.user;
        setUsers([
          {
            name: user_data.FirstName,
            lastName: user_data.LastName,
            email: user_data.Email,
            role: user_data.UserRole,
          },
        ]);
        setIsSelectDisabled(user_data.UserRole === roles["admin"].label);
        setSelectedDealerships(user_data.ChildAccounts);
      } else {
        dispatch(
          chooseUserManagementSnackbarState({
            severity: "error",
            message: "User for edit was not found",
            open: true,
          })
        );
        history.push("/user-management");
      }
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (Object.values(validationErrors).length) {
      setAlertState({
        severity: "error",
        message: Object.values(validationErrors)[0],
        open: true,
      });
    }
  }, [validationErrors]);

  useEffect(() => {
    if (JSON.stringify(requestOptions) !== "{}") {
      executeApiCall();
      setAlertState({
        severity: "info",
        message: savingInProgressMessage,
        open: true,
      });
    }
  }, [requestOptions]);

  useEffect(() => {
    if (status === "error") {
      dispatch(
        chooseUserManagementSnackbarState({
          severity: "error",
          message: "An error happened during saving users",
          open: true,
        })
      );
      history.push("/user-management");
    }
    if (status === "success") {
      if (isEdit) {
        dispatch(
          chooseUserManagementSnackbarState({
            severity: "success",
            message: "User Updated Successfully!",
            open: true,
          })
        );
      } else {
        if ((apiResponseData["failed users"] || []).length) {
          dispatch(
            chooseUserManagementSnackbarState({
              severity: "info",
              message: "Some Users Could not be Created",
              open: true,
            })
          );
        } else {
          dispatch(
            chooseUserManagementSnackbarState({
              severity: "success",
              message: "User(s) Created and Invited Successfully!",
              open: true,
            })
          );
        }
      }
      history.push("/user-management");
    }
  }, [status]);

  useEffect(() => {
    if (getRetailerName() === 'cdk_global') {
      setFilteredDealerships(_.uniq(auth.user_info.ChildAccounts, "id").filter(item => item.retailer_name === 'cdk_global'))
    } else {
      setFilteredDealerships(_.uniq(auth.user_info.ChildAccounts, "id"))
    }
  }, [auth.user_info.ChildAccounts])

  return (
    <div className="UserManagementForm">
      <Snackbar
        open={alertState.open}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={6000}
        onClose={() => setAlertState({ ...alertState, open: false })}
      >
        <SnackbarMessage
          elevation={2}
          variant="filled"
          onClick={() => {
            setAlertState({ ...alertState, open: false });
          }}
          severity={alertState.severity}
        >
          {alertState.message}
        </SnackbarMessage>
      </Snackbar>
      <WidgetBreadcrumbs breadcrumbs={breadcrumbs} />
      <h2 className="UserManagementForm-title">
        {isEdit ? "Edit" : "Add"} User
      </h2>
      <h3 className="UserManagementForm-subtitle">User Info</h3>
      {isLoading ? (
        <Loader />
      ) : (
        users.map((user, index) => (
          <>
            <div key={index} className="UserManagementForm-user">
              <div className="UserManagementForm-userInputs">
                <div className="UserManagementForm-dataInputContainer">
                  <FormElement
                    type="textfield"
                    label="First Name"
                    key={`name-${index}`}
                    value={user.name}
                    disabled={isEdit}
                    error={!!validationErrors[`name-${index}`]}
                    onChange={(e) => {
                      users[index] = {
                        ...user,
                        name: e.target.value,
                      };
                      setUsers([...users]);
                    }}
                  />
                </div>
                <div className="UserManagementForm-dataInputContainer">
                  <FormElement
                    type="textfield"
                    label="Last Name"
                    key={`last-name-${index}`}
                    value={user.lastName}
                    disabled={isEdit}
                    error={!!validationErrors[`last-name-${index}`]}
                    onChange={(e) => {
                      users[index] = {
                        ...user,
                        lastName: e.target.value,
                      };
                      setUsers([...users]);
                    }}
                  />
                </div>
                <div className="UserManagementForm-dataInputContainer">
                  <FormElement
                    type="textfield"
                    label="Email"
                    key={`email-${index}`}
                    value={user.email}
                    disabled={isEdit}
                    error={!!validationErrors[`email-${index}`]}
                    onChange={(e) => {
                      users[index] = {
                        ...user,
                        email: e.target.value,
                      };
                      setUsers([...users]);
                    }}
                  />
                </div>
                <div className="UserManagementForm-dataInputContainer">
                  <FormElement
                    type="select"
                    label={
                      <div className="UserManagementForm-inputLabelWithTooltip">
                        <span>ROLE</span>
                        <QuestionMark
                          w={14}
                          h={14}
                          definition={
                            <>
                              Admin - will have an access to all AI dashboard
                              pages including user management.
                              <br />
                              Regular - will have access to Metrics and
                              Conversation view only.
                            </>
                          }
                        />
                      </div>
                    }
                    values={Object.values(roles)}
                    disabled={isSelectDisabled}
                    defaultValue={
                      user.role
                        ? { id: user.role.toLowerCase(), label: user.role }
                        : roles["admin"]
                    }
                    key={`select-${index}`}
                    onChange={(value) =>
                      setUsers(
                        users.map((currentUser, currentIndex) => {
                          if (currentIndex === index) {
                            return {
                              ...user,
                              role: value.label,
                            };
                          }
                          return currentUser;
                        })
                      )
                    }
                  />
                </div>
              </div>
              <div className="UserManagementForm-removeUserButtonContainer">
                <button
                  type="button"
                  className={cn("UserManagementForm-removeUserButton", {
                    "UserManagementForm-removeUserButton_hidden":
                      users.length < 2,
                  })}
                  onClick={() => handleRemoveUser(user)}
                >
                  <Svg w="18" h="18" i="garbage" />
                </button>
              </div>
              {index === users.length - 1 && !isEdit && index !== 6 && (
                <button
                  className="UserManagementForm-addUserButton"
                  onClick={handleAddUser}
                >
                  <div className="UserManagementForm-addUserButtonIcon">
                    <Svg w="32" h="32" i="add" />
                  </div>
                  <div className="UserManagementForm-addUserButtonLabel">
                    Add User
                  </div>
                </button>
              )}
            </div>
          </>
        ))
      )}
      <h3 className="UserManagementForm-subtitle">Dealerships</h3>
      <p className="UserManagementForm-text">
        Please note that selected Dealerships will be applied for all users
        added above
      </p>
      <div>
        <div className="UserManagementForm-dealershipSearchContainer">
          <FormTextfield
            label="Search"
            icon="search"
            placeholder="Search Dealership..."
            onChange={(e) => setDealershipsSearch(e.target.value)}
          />
        </div>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <DealershipsTransferList
          dealerships={filteredDealerships}
          selectedDealerships={selectedDealerships}
          setSelectedDealerships={setSelectedDealerships}
          search={dealershipsSearch}
          error={!!validationErrors["dealerships"]}
        />
      )}
      <div className="UserManagementForm-lowerButtons">
        <FormElement
          className="UserManagementForm-cancelButton"
          type="linkSubmit"
          link="/user-management/"
          label={"Cancel"}
        />
        <FormElement
          type="submit"
          disabled={isLoading}
          onClick={handleSubmit}
          label={"Invite user"}
        />
      </div>
    </div>
  );
};

export default UserManagementForm;
