import "./Svg.scss";

import svg from "../../assets/img/vector.svg";

const Svg = ({ w, h, i, fill= null }) => {
  return (
    <svg className="Svg" width={w} height={h} style={fill ? {fill: fill} : {}}>
      <use xlinkHref={`${svg}#v-${i}`} />
    </svg>
  );
};

export default Svg;
