import Layout from "../../components/Layout";
import UserManagementTable from "../../components/UserManagementTable";
import WidgetSearch from "../../components/WidgetSearch/WidgetSearch";
import FormElement from "../../components/FormElement/FormElement";
import {chooseUserManagementQuery} from "../../store/actions/userManagementActions";
import {useState} from "react";
import useIsCdk from "../../hook/useIsCdk";


const PageUserManagement = () => {
  const isCDK = useIsCdk();
  const [selectedButton, setSelectedButton] = useState({id: "impel", label: 'Impel', link: "/user-management/add"});

  const widgets = {
  secondary: [
    <WidgetSearch key="widgetSearch" placeholder="Search Users..." functionToDispatch={chooseUserManagementQuery} />,
  ],
  primary: isCDK ?
    [
      <FormElement type="linkSubmit" link="/user-management/add" label={'Add User'} />
    ] :
    [
      <FormElement type="choicesLinkSubmit"
        values={[{id: "impel", label: 'Impel', link: "/user-management/add"}, {id: "cdk", label: 'CDK', link: "/user-management/add?cdk=cdk_global"}]}
        defaultValue={selectedButton}
        onChange={setSelectedButton}
        labelHidden={true}
        label={`Add ${selectedButton.label} User`} />
    ]
  };

  return <Layout content={<UserManagementTable />} widgets={widgets} />;
};

export default PageUserManagement;
