import { useContext, useEffect } from "react";
import useData from "../../../../../hook/useData";
import { StoreContextSettingsCMSPage } from "../../SettingsCMS";


const DeleteForm = () => {
    const { stateCMS, dispatchCMS } = useContext(StoreContextSettingsCMSPage);

    const {
        data: deletedData,
        status: deleteStatus,
        execute: deleteExecute,
    } = useData("deleteQuestionOrTopic", stateCMS?.deleteState?.state, [stateCMS?.deleteState?.state], false);

    useEffect(() => {
        if(deletedData?.status){
            if(stateCMS?.deleteState?.state.key === "question"){
                dispatchCMS({ type: "deleteQuestion", topicId: stateCMS?.deleteState?.state.delete_information.topic_id, questionId: stateCMS?.deleteState?.state.delete_information.question_id });
            }else{
                dispatchCMS({ type: "deleteTopic", topicId: stateCMS?.deleteState?.state.delete_information.topic_id });
            }  
          dispatchCMS({ type: "changePrimitiveType", propertyId: "openModal", value: false})
        }
    },[deletedData])

    return(
        <form className="SettingsCMS-ModalBlok">
            <p>Delete { stateCMS?.deleteState?.type === "topic" ? "Topic" : "Question"}</p>
            <p style={{ color: "gray", fontWeight: "50" }}>
                {
                    stateCMS?.deleteState?.type === "topic" ?
                    "Are you sure you want to delete the topic ? All the questions related to this topic will be deleted as well. You cannot undo this action."
                    :
                    "Are you sure you want to delete the question ? You cannot undo this action."
                }
            </p>
            <div style={{ display: "flex", gap: "20px" }}>
                <button type="button" className="Delete-cancel" onClick={() => dispatchCMS({ type: "changePrimitiveType", propertyId: "openModal", value: false})}>Cancel</button>
                <button type="button" className="Delete-button" onClick={() =>  deleteExecute()}>Delete {stateCMS?.deleteState?.type === "topic" ? "Topic" : "Question"}</button>
            </div>
            <button type="button" className="SettingsCMS-ModalCloseBtn" onClick={() => dispatchCMS({ type: "changePrimitiveType", propertyId: "openModal", value: false})}>
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                    <g id="X" transform="translate(-952 -256)">
                    <circle id="Ellipse_150" dataName="Ellipse 150" cx="16" cy="16" r="16" transform="translate(952 256)" fill="#f7f7f7"/>
                    <g id="Group_1501" dataName="Group 1501" transform="translate(-16.5 -1074.5)">
                        <line id="Line_155" dataName="Line 155" x2="10" y2="10" transform="translate(979.5 1341.5)" fill="none" stroke="#000" strokeWidth="1.5"/>
                        <line id="Line_156" dataName="Line 156" y1="10" x2="10" transform="translate(979.5 1341.5)" fill="none" stroke="#000" strokeWidth="1.5"/>
                    </g>
                    </g>
                </svg>
            </button>
        </form>
    )
}

export default DeleteForm;