import React, { useEffect, useState } from "react";
import { FormControlLabel, FormGroup } from "@material-ui/core";
import { IOSSwitch } from "../settingsDealerChatBusinessHours/Toogle/Toggle";
import FormElement from "../../FormElement";
import "./SettingsDealershipTakeover.scss";
import useData from "../../../hook/useData";
import { useSelector } from "react-redux";
import { Snackbar } from "@mui/material";
import SnackbarMessage from "../../SnackbarMessage/SnackbarMessage";

const SettingsDealershipTakeover = ({ type, renderedCallback = () => {} }) => {
  const accountId = useSelector(
    (state) => state.user.auth?.data?.user_info?.DealerId
  );
  const {
    data: convensionData,
    status: convensionStatus,
    error: convensionError,
    execute: fetchConversionData,
  } = useData(
    "getConvensionTakeoverStatus",
    {
      account_id: accountId,
    },
    [accountId]
  );

  const {
    data: serviceSchedulerStatusData,
    status: serviceSchedulerStatusStatus,
    error: serviceSchedulerStatusError,
    execute: serviceSchedulerStatusExecute,
  } = useData(
    "getChatServiceSchedulerStatus",
    {
      account_id: accountId,
    },
    [accountId],
    false
  );

  const { data, status, error } = useData(
    "getDealershipTakeoverStatus",
    {
      account_id: accountId,
    },
    [accountId]
  );

  const [dealershipTakeoverStatus, setDealershipTakeoverStatus] = useState(
    data?.Status
  );

  const [conversionsStatus, setConversionsStatus] = useState(
    convensionData?.Status || false
  );
  const [conversationalServiceScheduling, setConversationalServiceScheduling] =
    useState(serviceSchedulerStatusData?.Status || false);
  const [visibleServiceScheduling, setVisibleServiceScheduling] = useState(
    serviceSchedulerStatusData?.Visible || false
  );
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);

  const { status: updatedStatus, execute: updateTakeoverStatus } = useData(
    "updateDealershipTakeoverStatus",
    {
      account_id: accountId,
      status: dealershipTakeoverStatus,
    },
    [accountId, dealershipTakeoverStatus],
    false
  );

  const {
    status: updatedConversionStatus,
    execute: updateConvensionStatus,
    error: convenstionUpdateError,
  } = useData(
    "updateConversionStatus",
    {
      account_id: accountId,
      status: conversionsStatus,
    },
    [accountId, conversionsStatus],
    false
  );

  const {
    status: updateChatServiceSchedulerStatus,
    execute: updateChatServiceScheduler,
    error: updateChatServiceSchedulerError,
  } = useData(
    "updateChatServiceSchedulerStatus",
    {
      account_id: accountId,
      status: conversationalServiceScheduling,
    },
    [accountId, conversationalServiceScheduling],
    false
  );

  useEffect(async () => {
    if (type === "MAXIMIZE_CONVERSIONS") {
      await serviceSchedulerStatusExecute();
    }
  }, []);

  useEffect(() => {
    if (serviceSchedulerStatusData) {
      setVisibleServiceScheduling(serviceSchedulerStatusData.Visible || false);
      setConversationalServiceScheduling(
        serviceSchedulerStatusData.Status || false
      );
    }
  }, [serviceSchedulerStatusData]);

  useEffect(() => {
    setDealershipTakeoverStatus(data?.Status);
    setConversionsStatus(convensionData?.Status);

    renderedCallback();
  }, [data, convensionData]);

  const handleDealershipTakeoverStatusChange = () => {
    setDealershipTakeoverStatus((prevState) => !prevState);
  };

  const handleConversionsStatusChange = (serviceScheduler = false) => {
    if (serviceScheduler) {
      setConversationalServiceScheduling((prevState) => !prevState);
    } else {
      setConversionsStatus((prevState) => !prevState);
    }
  };

  const handleSubmitData = async () => {
    setLoading(true);
    if (type === "DEALERSHIP_TAKEOVER") {
      await updateTakeoverStatus();
    }
    if (type === "MAXIMIZE_CONVERSIONS") {
      if (
        serviceSchedulerStatusData.Status !== conversationalServiceScheduling
      ) {
        await updateChatServiceScheduler();
        await serviceSchedulerStatusExecute();
      }
      if (convensionData.Status !== conversionsStatus) {
        await updateConvensionStatus();
        await fetchConversionData();
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    if (convenstionUpdateError !== null) {
      setShowError(true);
    }
  }, [convenstionUpdateError]);

  if (error) {
    return <div>Can't Fetch Data</div>;
  }

  return (
    <>
      <Snackbar
        style={{ marginTop: "76px" }}
        open={showError}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={6000}
        onClose={() => setShowError(false)}
      >
        <SnackbarMessage
          elevation={2}
          variant="filled"
          onClick={() => {
            setShowError(false);
          }}
          severity={"error"}
        >
          Error updating settings. Please try in a few minutes. If the problem
          persists contact our Support team.
        </SnackbarMessage>
      </Snackbar>
      <div className="StatusContent">
        {type === "DEALERSHIP_TAKEOVER" && (
          <div className="ActionsArea">
            <div className="StatusContentLabel">Dealership Takeover</div>
            <FormGroup>
              <FormControlLabel
                control={
                  <IOSSwitch
                    checked={!!dealershipTakeoverStatus}
                    onChange={handleDealershipTakeoverStatusChange}
                    name="Toggle"
                  />
                }
                label={dealershipTakeoverStatus ? "Enabled" : "Disabled"}
              />
            </FormGroup>
          </div>
        )}
        {type === "MAXIMIZE_CONVERSIONS" && (
          <div>
            <div className="ActionsArea">
              <div className="StatusContentLabel">Maximize Conversions</div>
              <FormGroup id="conversionToggle">
                <FormControlLabel
                  id="conversionToggle"
                  control={
                    <IOSSwitch
                      checked={!!conversionsStatus}
                      onChange={() => handleConversionsStatusChange(false)}
                      name="Toggle"
                    />
                  }
                  label={conversionsStatus ? "Enabled" : "Disabled"}
                />
              </FormGroup>
            </div>
            {visibleServiceScheduling && (
              <div className="ActionsArea">
                <div className="StatusContentLabel">
                  Conversational Service Scheduling
                </div>
                <FormGroup id="ServiceSchedulerToggle">
                  <FormControlLabel
                    id="ServiceSchedulerToggle"
                    control={
                      <IOSSwitch
                        checked={conversationalServiceScheduling}
                        onChange={() => handleConversionsStatusChange(true)}
                        name="ServiceSchedulerToggle"
                      />
                    }
                    label={
                      conversationalServiceScheduling ? "Enabled" : "Disabled"
                    }
                  />
                </FormGroup>
              </div>
            )}
          </div>
        )}
      </div>
      <div className="SettingsTime-save">
        <FormElement
          onClick={handleSubmitData}
          type="submit"
          label={
            type === "DEALERSHIP_TAKEOVER"
              ? "SAVE DEALERSHIP TAKEOVER STATUS"
              : "SAVE capabilities"
          }
          disabled={loading}
        />
      </div>
    </>
  );
};

export default SettingsDealershipTakeover;
