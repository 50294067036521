import { useEffect, useState } from "react";

import cn from "classnames";

import Table from "../../Table";
import Loader from "../../Loader";

import useData from "../../../hook/useData";

import tableColumnsAccess from "./tableColumnsAccess";

import "./SettingsAccess.scss";
import { useSelector } from "react-redux";

const SettingsAccess = ({renderedCallback = () => {}}) => {
  const [state, setState] = useState(null);
  const [formData, setFormData] = useState(null);
  const [freezeTable, setFreezeTable] = useState(false);
  const accountId = useSelector(
    (state) => state.user.auth?.data?.user_info?.DealerId
  );
  const selectedProducts = useSelector(
    (state) => state.user.auth?.data?.user_info?.selectedProduct
  );

  const { data, status, error } = useData("getAccountSourceSettings", {}, [
    accountId,
    selectedProducts,
  ]);

  const {
    data: updateData,
    status: updateStatus,
    execute: updateExecute,
  } = useData("updateAccountSourceSettings", formData, [formData], false);

  useEffect(() => {
    if (data?.Items) {
      let updatedData = data.Items.map((dataItem) => {
        return { ...dataItem, expanded: false };
      });

      setState(updatedData);
      renderedCallback();
    }
  }, [data]);

  useEffect(() => {
    if (updateStatus === "success") {
      setState((state) => {
        const sourceIdx = state.findIndex((source) => {
          return source.SourceId === updateData.SourceId;
        });

        if (sourceIdx === -1) {
          return state;
        }

        const newRow = {
          ...state[sourceIdx],
          Status: updateData.Status,
          SourceName: updateData.SourceName,
          SourceId: updateData.SourceId,
          updatedSubsources: updateData.UpdatedSubSources,
        };

        return [
          ...state.slice(0, sourceIdx),
          newRow,
          ...state.slice(sourceIdx + 1),
        ];
      });

      setFormData(null);
    }
  }, [updateStatus, updateData]);

  useEffect(() => {
    if (formData && formData.SubSourceNum !== null) {
      updateExecute();
    }
  }, [formData, updateExecute]);

  const handleChangeSourceStatus = (row) => {
    setFormData({
      source_id: row.SourceId,
      Status: !row.Status,
      SourceName: row.SourceName,
      SubSourceNum: row.SubSourceNum,
    });
  };

  const handleExpandButtonClick = (row) => {
    // Collapse other expanded rows, if not collapsed, subsource toggle does not work.
    setState((prevState) => {
      const prevExpandedRowIdx = prevState.findIndex((source) => {
        return source.expanded === true && source.SourceId !== row.SourceId;
      });
      if (prevExpandedRowIdx === -1 || prevExpandedRowIdx === undefined) {
        return prevState;
      }

      const updateMe = prevState[prevExpandedRowIdx];
      updateMe.expanded = false;

      return [
        ...prevState.slice(0, prevExpandedRowIdx),
        updateMe,
        ...prevState.slice(prevExpandedRowIdx + 1),
      ];
    });

    // Expand the row that was clicked
    setState((prevState) => {
      const sourceIdx = prevState.findIndex((source) => {
        return source.SourceId === row.SourceId;
      });
      return [
        ...prevState.slice(0, sourceIdx),
        {
          ...row,
          expanded: !row.expanded,
        },
        ...prevState.slice(sourceIdx + 1),
      ];
    });
    console.log("row to expand is: ", row);
  };

  const render = () => {
    if (status === "error") {
      return error || "Error";
    }

    if (status === "pending" || status === "init" || state === null) {
      return <Loader />;
    }

    return (
      <div
        className={cn("SettingsAccess", {
          "SettingsAccess--disabled": freezeTable || updateStatus === "pending",
        })}
      >
        <div className="SettingsAccess-wrapper">
          <Table
            scrollable={true}
            columns={tableColumnsAccess}
            rows={state}
            events={{
              cells: [
                {
                  events: {
                    handleStatus: handleChangeSourceStatus,
                    handleExpand: handleExpandButtonClick,
                  },
                  id: "SourceId",
                },
              ],
            }}
            options={{ layoutFixed: true }}
            setFreezeTable={setFreezeTable}
          />
        </div>
      </div>
    );
  };

  return render();
};

export default SettingsAccess;
