import { useEffect, useRef, useState } from "react";
import Modal from "@material-ui/core/Modal";
import FormElement from "../../FormElement";
import "./ChatsReply.scss";
import ContactFormModalLayout from "../ContactFormModalLayout/ContactFormModalLayout";
import Close from "../../../assets/img/Close";
import ExpandIcon from "../../../assets/img/ExpandIcon";
import SplittedSendButton from "./SplittedSendButton/SplittedSendButton";
import { Snackbar } from "@mui/material";
import SnackbarMessage from "../../SnackbarMessage/SnackbarMessage";
import { useSelector } from "react-redux";
import AppointmentModalLayout from "../AppointmentModalLayout/AppointmentModalLayout";
import Confirmation from "../../Confirmation/Confirmation";

const ChatsReply = ({
  conversation,
  selectedChannel,
  refetchConversation,
  unsubscribed,
  handleSendMessage,
  handleEndConversationClick,
  disableSend,
  generalInfoData,
  refetchGeneralInfoData,
}) => {
  const isValid = useSelector((state) => state.conversationDealerChat.valid);
  const answerPanelContainer = useRef(null);
  const [newMessageObject, setNewMessageObject] = useState({
    text: "",
    html: "",
  });
  const [isAppointmentModalOpen, setIsAppointmentModalOpen] = useState(false);
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const [shrinkModal, setShrinkModal] = useState(false);
  const [closeModal, setCloseModal] = useState(false);
  const [isShrinkClose, setIsShrinkClose] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSubmit(event);
    }
  };

  useEffect(() => {
    if (answerPanelContainer.current) {
      answerPanelContainer.current.addEventListener("keydown", handleKeyDown);
    }
  }, []);

  const handleSubmit = (event) => {
    if (disableSend) return;
    event.preventDefault();
    answerPanelContainer.current.innerText =
      answerPanelContainer.current.innerHTML;
    handleSendMessage({
      text: answerPanelContainer.current.innerText.trim(),
      html: answerPanelContainer.current.innerHTML.trim(),
      _sending: true,
    });
    answerPanelContainer.current.innerHTML = "";
    answerPanelContainer.current.innerText = "";
    setNewMessageObject({
      ...newMessageObject,
      text: "",
      html: "",
    });
  };

  const handleEndConversation = (event) => {
    event.preventDefault();
    handleEndConversationClick();
  };

  return (
    <>
      <Snackbar
        style={{ marginTop: "76px" }}
        open={showSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={6000}
        onClose={() => setShowSnackbar(false)}
      >
        <SnackbarMessage
          elevation={2}
          variant="filled"
          onClick={() => {
            setShowSnackbar(false);
          }}
          severity={"success"}
        >
          The Form Has Been Successfully Submitted!
        </SnackbarMessage>
      </Snackbar>
      <Modal
        open={isContactModalOpen}
        onClose={() => {
          setIsContactModalOpen(false);
        }}
        className="Chat-Form-Modal"
        style={shrinkModal ? { display: "none" } : {}}
      >
        <ContactFormModalLayout
          changeModalState={setIsContactModalOpen}
          setShrinkModal={setShrinkModal}
          closeModal={setCloseModal}
          generalInfoData={generalInfoData}
          setIsShrinkClose={setIsShrinkClose}
          refetchGeneralInfoData={refetchGeneralInfoData}
          setShowSnackbar={setShowSnackbar}
        />
      </Modal>
      <Modal
        open={isAppointmentModalOpen}
        onClose={() => {
          setIsAppointmentModalOpen(false);
        }}
        className="Chat-Form-Modal"
        style={shrinkModal ? { display: "none" } : {}}
      >
        <AppointmentModalLayout
          changeModalState={setIsAppointmentModalOpen}
          setShrinkModal={setShrinkModal}
          closeModal={setCloseModal}
          setCloseModal={setCloseModal}
          setIsShrinkClose={setIsShrinkClose}
          setShowSnackbar={setShowSnackbar}
        />
      </Modal>
      {shrinkModal && (
        <div className="ShrinkArea">
          <span className="ShrinkAreaTitle">
            {isContactModalOpen ? "Contact Form" : "Appointment Form"}
          </span>
          <div className="ShrinkActions">
            <div
              onClick={() => {
                setShrinkModal(false);
                setIsShrinkClose(false);
              }}
            >
              <ExpandIcon />
            </div>
            <div
              onClick={() => {
                if (isValid) {
                  setIsShrinkClose(true);
                  setCloseModal(true);
                } else {
                  setShrinkModal(false);
                  setIsAppointmentModalOpen(false);
                  setIsContactModalOpen(false);
                }
              }}
            >
              <Close />
            </div>
          </div>
        </div>
      )}
      <Confirmation
        isOpen={closeModal}
        closeDialog={() => {
          setCloseModal(false);
          if (isShrinkClose) {
            setShrinkModal(true);
          } else {
            isContactModalOpen
              ? setIsContactModalOpen(true)
              : setIsAppointmentModalOpen(true);
            setShrinkModal(false);
          }
        }}
        title="Close Form"
        text="Closing this form will discard any unsaved changes. &&
Do you want to continue?"
        cancelButtonTitle="Cancel"
        confirmButtonTitle="Continue"
        handleConfirmDelete={() => {
          setCloseModal(false);
          setShrinkModal(false);
          setIsContactModalOpen(false);
          setIsAppointmentModalOpen(false);
        }}
      />
      <form className="ChatsReply">
        <div
          ref={answerPanelContainer}
          spellCheck={false}
          dangerouslySetInnerHTML={{ __html: newMessageObject.html }}
          className="ChatReply__editable-div scrollable"
          contentEditable
        ></div>
        <div className="Actions">
          <SplittedSendButton
            onClickContact={() => {
              setIsContactModalOpen(true);
              setIsAppointmentModalOpen(false);
            }}
            onSubmitClick={(event) => handleSubmit(event)}
            onClickAppointment={() => {
              setIsAppointmentModalOpen(true);
              setIsContactModalOpen(false);
            }}
          />
          <div className="EndConversation">
            <FormElement
              stype={{ backgroundColor: "black" }}
              type="submit"
              label="End Conversation"
              id="endConversation"
              name="endConversation"
              value="End Conversation"
              onClick={(event) => handleEndConversation(event)}
            />
          </div>
        </div>
      </form>
    </>
  );
};

export default ChatsReply;
