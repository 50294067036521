import { useEffect, useState, useRef } from "react";

import cn from "classnames";

import FormElement from "../../FormElement";
import Loader from "../../Loader";

import useData from "../../../hook/useData";

import FORM_FIELDS from "./formStructure";

import "./SettingsSignature.scss";
import { HelpOutline } from "@material-ui/icons";
import { Tooltip } from "@material-ui/core";
import { useSelector } from "react-redux";

const SettingsSignature = (props) => {
  const [reset, setReset] = useState({});

  return (
    <SettingsSignatureExtended
      {...props}
      previewResetter={reset}
      setPreviewResetter={setReset}
    />
  );
};

const SettingsSignatureExtended = ({
  accessConfig = {},
  refetch = () => {},
  close = () => {},
  previewResetter,
  setPreviewResetter,
  renderedCallback = () => {}
}) => {
  const isCDK = window.location.hostname.includes('crmaiva');
  const previewRef = useRef(null);
  const [state, setState] = useState(null);
  const [displayKeyOfSignature, setDisplayKeyOfSignature] = useState(false);
  const [editSuggestion, setEditSuggestion] = useState(false);
  const [updateError, setUpdateError] = useState(null);
  const [resourceName, setResourceName] = useState(
    accessConfig?.resource?.Name
  );
  const accountId = useSelector(
    (state) => state.user.auth?.data?.user_info?.DealerId
  );

  const { accessType, resource } = accessConfig;

  const { data, status, error } = useData(
    "getAccountGeneralInfo",
    {},
    [accountId],
    true
  );

  const {
    data: updatedData,
    status: updateStatus,
    execute: updateExecute,
  } = useData("updateAccountGeneralInfo", state, [state], false);

  const {
    // data: updateResourceData,
    status: updateResourceStatus,
    execute: updateResourceExecute,
  } = useData("updateAccountGeneralInfoResource", state, [state], false);

  const {
    // data: createData,
    status: createStatus,
    execute: createExecute,
  } = useData("createAccountGeneralInfoResource", state, [state], false);

  useEffect(() => {
    if (data) {
      setState(data);
      setDisplayKeyOfSignature(data.Signature ? "Signature" : "");

      renderedCallback();
    }
  }, [data, accountId]);

  useEffect(() => {
    if (resource) {
      const newState = {
        ...JSON.parse(JSON.stringify(resource.Resource)),
      };

      if (accessType === "write") {
        newState.resource_id = resource.Id;
      }

      setDisplayKeyOfSignature(newState.Signature ? "Signature" : "");

      setState(newState);
    }
  }, [resource, accountId]);

  useEffect(() => {
    if (updateStatus === "success") {
      setUpdateError(null);
      setState(updatedData);
      setDisplayKeyOfSignature(updatedData.Signature ? "Signature" : "");
    }
    if (updateStatus === "error") setUpdateError("Could not update signature");
  }, [updateStatus]);

  useEffect(() => {
    if (updateResourceStatus === "success") {
      close();
      refetch();
    }
  }, [updateResourceStatus]);

  useEffect(() => {
    if (createStatus === "success") {
      close();
      refetch();
    }
  }, [createStatus]);

  const handleFieldChange = (fieldStructure, e) => {
    const value = e.target.value;

    resetPreview();

    setState((state) => {
      return {
        ...state,
        [fieldStructure.key]: value,
      };
    });
  };

  const handleResourceNameChange = (value) => {
    setResourceName(value);
    setState({
      ...state,
      ResourceName: value,
    });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    if (accessType === "create") {
      createExecute();
    } else if (accessType === "write") {
      updateResourceExecute();
    } else {
      updateExecute();
    }
  };

  const getButtonActionLabel = () => {
    if (accessType === "write") return "save";
    if (accessType === "create") return "create";
    return "save";
  };

  const replaceBreakLines = (str) => {
    if (!str) return str;
    let arr = str.split("\n");
    let res = [];
    for (let i = 0; i < arr.length; i++) {
      res.push(arr[i]);
      res.push(<br />);
    }
    return res;
  };

  const getPreviewBody = () => {
    const preview = [];

    preview.push(
      <span className="SettingsSignature-previewName-x" contentEditable={false}>
        Best Regards,
      </span>
    );
    preview.push(<br />);
    preview.push(<br />);

    preview.push(
      <span className="SettingsSignature-previewName-x" contentEditable={false}>
        {state.FullName}
      </span>
    );
    preview.push(<br />);

    preview.push(
      <span
        className="SettingsSignature-previewPosition-x"
        contentEditable={false}
      >
        {state.Position}
      </span>
    );
    preview.push(<br />);
    preview.push(<br />);

    preview.push(
      <span
        className="SettingsSignature-previewAddress-x"
        contentEditable={false}
      >
        {state.DealershipName}
      </span>
    );
    preview.push(<br />);

    preview.push(
      <span
        className="SettingsSignature-previewAddress-x"
        contentEditable={false}
      >
        {replaceBreakLines(state.Address)}
      </span>
    );
    preview.push(<br />);

    if (state.MainPhone) {
      preview.push(
        <span
          className="SettingsSignature-previewPhones-x"
          contentEditable={false}
        >
          Sales: {replaceBreakLines(state.MainPhone)}
        </span>
      );
    }

    if (state.SecondaryPhone) {
      preview.push(
        <span
          className="SettingsSignature-previewPhones"
          contentEditable={false}
        >
          Service: {replaceBreakLines(state.SecondaryPhone)}
        </span>
      );
    }

    if (state.PartsPhone) {
      preview.push(
        <span
          className="SettingsSignature-previewPhones"
          contentEditable={false}
        >
          Parts: {replaceBreakLines(state.PartsPhone)}
        </span>
      );
      preview.push(<br />);
    }

    preview.push(
      <span
        className="SettingsSignature-previewWebsite-x"
        contentEditable={false}
      >
        {replaceBreakLines(state.WebsiteUrl)}
      </span>
    );

    return preview;
  };

  const resetPreview = () => {
    setDisplayKeyOfSignature(false);
    setPreviewResetter(null);
    setTimeout(() => {
      setPreviewResetter({});
      setState((oldState) => ({
        ...oldState,
        Signature: getCustomFormattedSignatureProcessed(
          previewRef.current.innerText
        ),
      }));
    }, 1000);
  };

  const displayResetSuggestion = () => {
    setEditSuggestion(true);
    setTimeout(() => {
      setEditSuggestion(false);
    }, 2000);
  };

  const getInitialHTML = (text) => {
    text = text.replace(/(?:\r\n|\r|\n)/g, "<br>");
    const indexOfUnsubscribe = text.indexOf("To unsubscribe");
    if (indexOfUnsubscribe === -1) {
      return text;
    }
    return text.substring(0, text.indexOf("To unsubscribe"));
  };

  const previewer = () => {
    if (displayKeyOfSignature) {
      return (
        <div className="SettingsSignature-preview">
          <div className="SettingsSignature-previewLabel">
            Signature preview
            <p>
              To see how the final signature will look, click “Save Signature.”
              This view will automatically populate with the details you’ve
              entered. You can also add custom text within this box by clicking
              “edit” (note: input fields cannot be edited in this view).
            </p>
          </div>
          <div
            className="SettingsSignature-preview-editor"
            contentEditable={false}
            ref={previewRef}
            spellCheck={false}
            dangerouslySetInnerHTML={{
              __html: getInitialHTML(state[displayKeyOfSignature]),
            }}
            onClick={displayResetSuggestion}
          ></div>
          {editSuggestion && (
            <span className="SettingsSignature-reset-suggestion">
              please reset to edit content
            </span>
          )}
          <div
            className={cn("SettingsSignature-reset", {
              "SettingsSignature-reset-strong": editSuggestion,
            })}
            onClick={resetPreview}
          >
            {isCDK ? 'edit' : 'Edit'}
          </div>
        </div>
      );
    }

    return (
      <>
        {previewResetter ? (
          <div className="SettingsSignature-preview">
            <div className="SettingsSignature-previewLabel">
              Signature preview
              <p>
                To see how the final signature will look, click “Save
                Signature.” This view will automatically populate with the
                details you’ve entered. You can also add custom text within this
                box by clicking “edit” (note: input fields cannot be edited in
                this view).
              </p>
            </div>
            <div
              className="SettingsSignature-preview-editor"
              contentEditable={true}
              ref={previewRef}
              spellCheck={false}
              onInput={() =>
                setState((oldState) => ({
                  ...oldState,
                  Signature: getCustomFormattedSignatureProcessed(
                    previewRef.current.innerText
                  ),
                }))
              }
            >
              {getPreviewBody()}
            </div>
            <div className="SettingsSignature-reset" onClick={resetPreview}>
              {isCDK ? 'edit' : 'Edit'}
            </div>
          </div>
        ) : (
          <div className="SettingsSignature-preview">
            <Loader />
          </div>
        )}
      </>
    );
  };

  const urlify = (text) =>
    text.replace(
      /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/g,
      // /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/g,
      (url) =>
        url.includes("http")
          ? `<a href="${url}" target="_blank" rel="noreferrer">${url}</a>`
          : `<a href="//${url}" target="_blank" rel="noreferrer">${url}</a>`
    );

  const getCustomFormattedSignatureProcessed = (signature) => {
    let result = signature.replace(/(?:\r\n|\r|\n)/g, "<br>");
    result = urlify(result);
    return result;
  };

  const render = () => {
    if (status === "error") {
      return error || "Error";
    }

    if (status === "pending" || state === null) {
      return <Loader />;
    }

    return (
      <>
        <form
          className={cn("SettingsSignature-form", {
            "SettingsSignature-form--disabled": updateStatus === "pending",
          })}
          onSubmit={handleFormSubmit}
        >
          {resource && (
            <div
              key={resource.Id}
              className={cn("SettingsSignature-formField", {
                "SettingsSignature-formField--wide": false,
              })}
            >
              <FormElement
                icon="info"
                hoverInfo="If desired, you can create different signatures for different initiatives. Enter a unique name to help identify which signature you’ve selected."
                type="textfield"
                defaultValue={resourceName}
                label="Signature Settings Nickname"
                id={resource.Id}
                key={resource.Id}
                readOnly={accessType === "write"}
                onChange={(e) => handleResourceNameChange(e.target.value)}
              />
              {/* <div>
                <Tooltip
                  title={
                    <h2>
                      If desired, you can create different signatures for
                      different campaigns. Enter a unique name to help identify
                      which signature you’ve selected.
                    </h2>
                  }
                >
                  <HelpOutline style={{ fill: "grey", cursor: "pointer" }} />
                </Tooltip>
              </div> */}
            </div>
          )}
          {FORM_FIELDS.map(({ isWide = false, ...field }, index) => {
            return (
              <div
                key={index}
                className={cn("SettingsSignature-formField", {
                  "SettingsSignature-formField--wide": isWide,
                })}
              >
                <FormElement
                  {...field}
                  defaultValue={data[field.key]}
                  onChange={(e) => handleFieldChange(field, e)}
                  disabled={updateStatus === "pending"}
                  readOnly={accessType === "read"}
                />
              </div>
            );
          })}
          {accessType !== "read" && (
            <div
              key="signature-form-submit"
              className={cn("SettingsSignature-formField", {
                "SettingsSignature-formField--wide": true,
              })}
            >
              <span>{updateError}</span>
              <FormElement
                type="submit"
                label={`${getButtonActionLabel()} signature`}
                disabled={
                  (accessType === "create" && !resourceName) ||
                  !state.DealershipName ||
                  !state.FullName ||
                  !state.MainPhone ||
                  !state.WebsiteUrl ||
                  !state.Address
                }
              />
            </div>
          )}
        </form>

        {previewer()}
      </>
    );
  };

  return <div className="SettingsSignature">{render()}</div>;
};

export default SettingsSignature;
