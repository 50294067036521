import cn from "classnames";

import Loader from "../../Loader";
import Svg from "../../Svg";

import { formatValue } from "../../../utils/formatValue";
import { checkAccountInsightProvider } from "../../../utils/checkAccountInsightProvider";
import { parseCustomerScore } from "../../../utils/parseObjects";
import imgFordDirectLogo from "../../../assets/img/ford-dir-white-transp-bg.png";
import { Tooltip, withStyles } from "@material-ui/core";
import useData from "../../../hook/useData";

import "./ChatsDetails.scss";
import { useSelector } from "react-redux";
import {useEffect} from "react";
import useIsCdk from "../../../hook/useIsCdk";

const ChatsDetails = ({ cid, onToggleDetailsCollapsed }) => {
  const isCDK = useIsCdk();
  const selectedProductId = useSelector(
    (state) => state?.user?.auth?.selectedProduct?.Category
  );

  const { data, status, error } = useData(
    "getGeneralLeadInfo",
    {
      cid,
    },
    [cid]
  );

  const hasBuyerScore = checkAccountInsightProvider("FORD", data?.AccountId);

  const changeToRequiredCase = (value) => {
    if (typeof value !== 'string' || value.length < 2) {
      return value;
    }

    if (isCDK) {
      return value.toUpperCase();
    }

    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
  }

  const FordTooltip = withStyles(() => ({
    tooltip: {
      backgroundColor: "#102B4E",
      color: "#fff",
      maxWidth: '26.5rem',
      padding: '1rem',
      border: "1px solid #102B4E",
      borderRadius: "0.8rem",
    },
    arrow: {
      "&:before": {
        border: "1px solid #102B4E"
      },
      color: "#102B4E"
    },
  }))(Tooltip);

  const render = () => {
    if (status === "error") {
      return error || "Error";
    }

    if (status === "init" || status === "pending") {
      return <Loader />;
    }

    const highlightedEvents = data.HighlightedEvents?.map((event, idx) => {
      const icon = event.Event === "SalespersonReplied" ? "man" : "bell";
      return (
        <div
          key={idx}
          className={cn("ChatsDetails-notification", {
            "is-important": event.HighlightType === 1,
          })}
        >
          <div className="ChatsDetails-notificationIcon">
            <Svg w="22" h="26" i={icon} />
          </div>
          <div className="ChatsDetails-notificationText">{event.EventName}</div>
        </div>
      );
    });

    return (
      <>
        <div className="ChatsDetails-heading" style={isCDK ? {} : {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}>
          <h2 className="ChatsDetails-name">{data.FullName}</h2>
          {isCDK ?
          <button
            className="ChatsDetails-toggler"
            onClick={onToggleDetailsCollapsed}
          >
            <span className="ChatsDetails-togglerLabel">Hide window</span>
            <span className="ChatsDetails-togglerIcon">
              {" "}
              <Svg w="24" h="7" i="arrowRight" />
            </span>
          </button> :
          <div className={"ChatsDetails-closeButton"} onClick={onToggleDetailsCollapsed}>
            <Svg w="20" h="20" i="close" />
          </div>
          }
        </div>
        <div className="ChatsDetails-info">
          <div className="ChatsDetails-infoLabel">Email:</div>
          <div className="ChatsDetails-infoValue">{data.Email}</div>

          <div className="ChatsDetails-infoLabel">Phone:</div>
          <div className="ChatsDetails-infoValue">{data.Phone}</div>

          <div className="ChatsDetails-infoLabel">Lead type:</div>
          <div className="ChatsDetails-infoValue">{data.Channel}</div>

          <div className="ChatsDetails-infoLabel">Lead source:</div>
          <div className="ChatsDetails-infoValue">{data.LeadSource}</div>

          {selectedProductId !== "SERVICE_AI" ? (
            <>
              <div className="ChatsDetails-infoLabel is-full">Lead status:</div>
              <div className="ChatsDetails-infoValue is-full">
                {data.LeadStatus}
              </div>

              <div className="ChatsDetails-infoLabel">Assigned to:</div>
              <div className="ChatsDetails-infoValue">{data.AssignedTo}</div>
            </>
          ) : null}

          {hasBuyerScore ? (
            <>
              <div className="ChatsDetails-infoLabel">Buyer score:</div>
              <div className="ChatsDetails-infoValue ChatDetails-infoValue--score">
                <span>{changeToRequiredCase(parseCustomerScore(data, "FORD"))}</span>
                <FordTooltip
                  placement="top"
                  arrow
                  title={
                    <div className="ChatsDetails-tooltipContainer">
                      <img src={imgFordDirectLogo} />
                      <p>Indicates the `likelihood to buy` score for a customer.</p>
                    </div>
                  }
                >
                  <a>
                    <Svg w="20" h="20" i="questionMark" fill={isCDK ? null : '#5336A9'} />
                  </a>
                </FordTooltip>
              </div>
            </>
          ) : null}
        </div>

        <div className="ChatsDetails-actions">
          {data.CrmProfileUrl && (
            <a
              className={isCDK ? "ChatsDetails-actionCDK" : "ChatsDetails-action"}
              href={data.CrmProfileUrl}
              target="_blank"
              rel="noreferrer"
            >
              <span className="ChatsDetails-actionLabel">CRM Profile</span>
              <span className="ChatsDetails-actionIcon">
                <Svg w="25" h="7" i="arrowRight" />
              </span>
            </a>
          )}

          {(isCDK || data.ReplyAssistant) && (
            <a
              className={isCDK ? "ChatsDetails-actionCDK" : "ChatsDetails-action"}
              href={isCDK ? window.location.href : data.ReplyAssistant}
              target="_blank"
              rel="noreferrer"
            >
              <span className="ChatsDetails-actionLabel">
                Reply as Assistant
              </span>
              <span className="ChatsDetails-actionIcon">
                <Svg w="25" h="7" i="arrowRight" />
              </span>
            </a>
          )}

          {(isCDK || data.StopAssistant) && (
            <a
              className={`${isCDK ? "ChatsDetails-actionCDK" : "ChatsDetails-action"} is-danger`}
              href={
                isCDK
                  ? `${window.location.pathname}/stop-communication/`
                  : data.StopAssistant
              }
              target="_blank"
              rel="noreferrer"
            >
              <span className="ChatsDetails-actionLabel">Stop Assistant</span>
              <span className="ChatsDetails-actionIcon">
                <Svg w="25" h="7" i="arrowRight" />
              </span>
            </a>
          )}
        </div>

        {data.VehicleInfo && (
          <div className="ChatsDetails-object">
            {data.VehicleInfo.Price && (
              <>
                <div className="ChatsDetails-objectName">
                  {data.VehicleInfo.Vehicle}
                </div>
                <div className="ChatsDetails-objectInfo">
                  <div className={isCDK ? "ChatsDetails-objectValueCDK" : "ChatsDetails-objectValue"}>{`$${formatValue(
                    +data.VehicleInfo.Price
                  )}`}</div>
                  <div
                    className={cn(isCDK ? "ChatsDetails-objectStatusCDK" : "ChatsDetails-objectStatus", {
                      "ChatsDetails-objectStatusCDK--available":
                        isCDK && data.VehicleInfo.Available,
                      "ChatsDetails-objectStatusCDK--unavailable":
                        isCDK && !data.VehicleInfo.Available,
                      "ChatsDetails-objectStatus--available":
                        !isCDK && data.VehicleInfo.Available,
                      "ChatsDetails-objectStatus--unavailable":
                        !isCDK && !data.VehicleInfo.Available,
                    })}
                  >
                    {data.VehicleInfo.Available ? "Available" : "Unavailable"}
                  </div>
                </div>
              </>
            )}
            {data.VehicleInfo.ImageUrl && (
              <div className="ChatsDetails-objectPhoto">
                <img src={data.VehicleInfo.ImageUrl} alt="" />
              </div>
            )}
          </div>
        )}

        <div className="ChatsDetails-notifications">{highlightedEvents}</div>
      </>
    );
  };

  return <div className="ChatsDetails">{render()}</div>;
};

export default ChatsDetails;
