import { Link } from "react-router-dom";
import cn from "classnames";

import Sidebar from "../Sidebar";
import Header from "../Header";
import AppSwitch from "../AppSwitch";

import "./Layout.scss";

import logo from "./assets/logoWhite.png";
import logoCDK from "./assets/logoCDK.svg";
import useIsCdk from "../../hook/useIsCdk";

const Layout = ({ content, widgets, className = "", withHeader = true, withAppSwitch = null }) => {
  const isCDK = useIsCdk();

  if (withAppSwitch === null) {
    const authItem = JSON.parse(localStorage.getItem("auth"));
    const category = authItem?.selectedProduct?.Category || authItem?.user_info?.selectedProduct?.Category;
    withAppSwitch = category === 'SALES_AI' && !isCDK;
  }

  return (
    <div className={cn("Layout", {"Layout_no-header": !withHeader}, {"Layout_noAppSwitch": !withAppSwitch}, className)}>
      {withAppSwitch ?
        <div className="Layout-appSwitch">
          <AppSwitch />
        </div>
      : null}

      <div className="Layout-logo">
        <Link to="/">
          <img src={isCDK ? logoCDK : logo} alt="" />
        </Link>
      </div>
      <div className="Layout-navigation">
        <Sidebar />
      </div>

      {withHeader &&
        <div className="Layout-widgets">
          <Header widgets={widgets} />
        </div>
      }

      <div className={cn("Layout-content", {"Layout-content_no-header": !withHeader})}>{content}</div>
    </div>
  );
};

export default Layout;
