import { useCallback, useEffect, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";

import req from "../utils/request";
import { useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

const useData = (
  endpoint,
  query,
  deps = [],
  immediate = true,
  restrictions = []
) => {
  const { user, isAuthenticated, error, logout } = useAuth0();
  const dispatch = useDispatch();
  const history = useHistory();
  let isMounted = true;
  const selectorState = useSelector((state) => state);
  const token = useSelector((state) => state.user.auth?.data?.access_token);
  const accountId = useSelector(
    (state) => state.user.auth?.data?.user_info?.DealerId
  );
  const productId = useSelector(
    (state) => state?.user.auth?.data?.user_info?.selectedProduct?.Id
  );

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      data: null,
      status: "init",
      error: null,
    }
  );

  useEffect(async () => {
    if (!user || !isAuthenticated || error) {
      await logout({
        logoutParams: { returnTo: window.location.origin }
      });
      localStorage.removeItem("auth");
      history.push("/login");
    }
  }, []);

  const getData = async () => {
    setState({ status: "pending" });
    try {
      const result = await req(
        endpoint,
        {
          ...{
            account_id: accountId,
            service_type: productId,
          },
          ...query,
        },
        token
      );

      if (!isMounted) return;

      if (result.error_code) {
        history?.push("/logout");
      }
      if (result.status === 401) {
        history?.push("/logout");
      }

      if (result.status === 400) {
        setState({
          status: "error",
          error: result.data.message || "Error",
          statusCode: result.status,
        });
      } else {
        setState({
          status: "success",
          data: result.data,
          statusCode: result.status,
        });
      }
    } catch (e) {
      setState({ status: "error", error: "Error", statusCode: null });
    }
  };

  const execute = useCallback(() => {
    if (productId && token && restrictions.every(value => !!value)) {
      return getData();
    }
  }, [...deps, ...restrictions, productId, token]);

  useEffect(() => {
    if (immediate) {
      execute();
    }

    return () => {
      isMounted = false;
    };
  }, [execute, immediate]);

  return {
    ...state,
    execute,
  };
};

export default useData;
