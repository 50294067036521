import { useAuth0 } from "@auth0/auth0-react";
import "./Login.scss";
import Loader from "../../components/Loader";

import FormElement from "../../components/FormElement";
import imgChat from "../../assets/img/login/chat.png";
import imgChecklist from "../../assets/img/login/checklist.png";
import imgGirl from "../../assets/img/login/girl.png";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Snackbar } from "@mui/material";
import SnackbarMessage from "../../components/SnackbarMessage";
import Logo from "../../assets/img/login/logo";
import useIsCdk from "../../hook/useIsCdk";

const PageLogin = () => {
  const { loginWithRedirect, user, isAuthenticated, isLoading } = useAuth0();
  const history = useHistory();
  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const stateAuth = useSelector((state) => state?.user?.auth);
  const isCrmaiva = useIsCdk();

  useEffect(() => {
    const authItem = JSON.parse(localStorage.getItem("auth"));
    if (authItem) {
      const isAuthUser = localStorage.getItem("isAuthUser");
      if (!isAuthUser || !authItem.selectedProduct) {
        localStorage.removeItem("auth");
        history?.push("/login");
      }
    }
  }, []);

  useEffect(() => {
    if (stateAuth?.isError) {
      setAlertIsOpen(true);
    }
  }, [stateAuth?.isError]);

  const handleAlertClose = () => {
    setAlertIsOpen(false);
  }

  // useEffect(async () => {
  //   if (isCrmaiva) {
  //     await loginWithRedirect();
  //   }
  // }, [])

  let render = () => {
    return (
      <div className="PageLogin__root">
        {/*<Snackbar*/}
        {/*  open={alertIsOpen || false}*/}
        {/*  anchorOrigin={{ vertical: "top", horizontal: "center" }}*/}
        {/*  autoHideDuration={6000}*/}
        {/*  onClose={handleAlertClose}*/}
        {/*>*/}
        {/*  <SnackbarMessage*/}
        {/*    elevation={2}*/}
        {/*    variant="filled"*/}
        {/*    className={"PageLogin__error-message"}*/}
        {/*    onClick={handleAlertClose}*/}
        {/*    severity="error"*/}
        {/*  >*/}
        {/*    {stateAuth?.errorMessage || "An error occurred"}*/}
        {/*  </SnackbarMessage>*/}
        {/*</Snackbar>*/}
        <div className="PageLogin__illustrations">
          <div className="checklist">
            <img src={imgChecklist} />
          </div>
          <div className="chat">
            <img src={imgChat} />
          </div>
          <div className="girl">
            <img src={imgGirl} />
          </div>
        </div>
        <div className="PageLogin__container">
          <div className="PageLogin__content">
            <div className="PageLogin__heading">
              <div className="PageLogin__heading-logo">
                <Logo />
              </div>
              <div>
                <p>Welcome to the AI Dashboard</p>
              </div>
            </div>
            <div>
              <FormElement
                type="submit"
                label="login"
                name="submit"
                style={{
                  margin: "1vh 0",
                  backgroundColor: "#410099",
                  minWidth: "15vw",
                }}
                onClick={async () => await loginWithRedirect()}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  if (isCrmaiva) {
    // return <div style={{
    //   width: "100%",
    //   height: "100vh",
    //   display: "flex",
    //   justifyContent: "center",
    //   alignItems: "center"
    // }}>
    //   <Loader />
    // </div>;
    render = () => {
      return (
        <div className="PageLoginCrmaiva__root">
          <Snackbar
            open={alertIsOpen || false}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            autoHideDuration={6000}
            onClose={handleAlertClose}
          >
            <SnackbarMessage
              elevation={2}
              variant="filled"
              className={"PageLoginCrmaiva__error-message"}
              onClick={handleAlertClose}
              severity="error"
            >
              {stateAuth?.errorMessage || "An error occurred"}
            </SnackbarMessage>
          </Snackbar>
          <div className="PageLoginCrmaiva__container">
            <div className="PageLoginCrmaiva__content">
              <div className="PageLoginCrmaiva__heading">
                <div className="PageLoginCrmaiva__heading-logo">
                  <img src="https://op3static.oktacdn.com/fs/bco/1/fs03fbz9a4KUsXei91d7" />
                </div>
                <div>
                  <p>Welcome to the Dashboard</p>
                </div>
              </div>
              <div>
                <FormElement
                  type="submit"
                  label="login"
                  name="submit"
                  style={{
                    margin: "1vh 0",
                    backgroundColor: "black",
                    borderRadius: "3px",
                    minWidth: "15vw",
                  }}
                  onClick={async () => await loginWithRedirect()}
                />
              </div>
            </div>
          </div>
        </div>
      );
    };
  }

  return render();
};

export default PageLogin;
