import "./DealershipsTransferList.scss";
import { Grid, List, ListItem } from "@mui/material";
import FormElement from "../../FormElement";
import { useEffect, useState } from "react";
import cn from "classnames";
import Svg from "../../Svg";
import logoCDK from "../assets/logoCDK.svg";
import useIsCdk from "../../../hook/useIsCdk";

const DealershipsTransferList = ({
  dealerships: unfilteredDealerships,
  selectedDealerships,
  setSelectedDealerships,
  search,
  error,
}) => {
  const isCDK = useIsCdk();
  const [dealerships, setDealerships] = useState(unfilteredDealerships);

  const arrayIncluded = (a1, a2) => {
    const a2Ids = a2.map(item => item.id);
    return a1.every(o => a2Ids.includes(o.id));
  }

  const clearSelectedDealerships = () => {
    setSelectedDealerships([]);
  }

  const toggleDealership = (value) => {
    if (!!selectedDealerships.filter((item) => item.id === value.id).length) {
      setSelectedDealerships(
        selectedDealerships.filter((item) => item.id !== value.id)
      );
    } else {
      setSelectedDealerships([...selectedDealerships, value]);
    }
  };

  const toggleAllDealerships = () => {
    const dealershipsIds = dealerships.map(item => item.id)
    const extraDealerships = selectedDealerships.filter(item => !dealershipsIds.includes(item.id));

    if (arrayIncluded(dealerships, selectedDealerships)) {
      setSelectedDealerships([...extraDealerships]);
    } else {
      setSelectedDealerships([...extraDealerships, ...dealerships]);
    }
  };

  useEffect(() => {
    const filteredDealerships = unfilteredDealerships.filter(item => item.name.toLowerCase().includes(search.toLowerCase()));
    setDealerships(filteredDealerships)
  }, [search])

  const createLeftList = () => {
    return <>
      <label
        className="DealershipsTransferList-label-left"
      >
        Select Dealerships
        {error &&
          <span className="DealershipsTransferList-label-error">Please select a dealership!</span>
        }
      </label>
      <List className="DealershipsTransferList-list">
        <ListItem
          key="All"
          className="DealershipsTransferList-listItem"
        >
          <img className="DealershipsTransferList-cdkIcon DealershipsTransferList-cdkIcon_hidden" src={logoCDK} alt="" />
          <FormElement
            type="checkbox"
            label='All'
            checked={arrayIncluded(dealerships, selectedDealerships)}
            onChange={() => toggleAllDealerships()}
          />
        </ListItem>
        {dealerships.map(value => {
          return (
            <ListItem
              key={value.id}
              className="DealershipsTransferList-listItem"
            >
              <img className={cn(
                "DealershipsTransferList-cdkIcon",
                {"DealershipsTransferList-cdkIcon_hidden": (value?.retailer_name !== 'cdk_global') || isCDK}
              )} src={logoCDK} alt="" />
              <FormElement
                type="checkbox"
                label={value.name}
                checked={!!selectedDealerships.filter(item => item.id === value.id).length}
                onChange={() => toggleDealership(value)}
              />
            </ListItem>
          );
        })}
      </List>
    </>
  }

  const createRightList = () => {
    return (
      <>
        <label className="DealershipsTransferList-label-right">
          {selectedDealerships.length} Selected
          <button
            type="button"
            className="DealershipsTransferList-clearAllButton"
            onClick={() => clearSelectedDealerships()}
          >
            Clear All
          </button>
        </label>
        <List
          className={cn("DealershipsTransferList-list", {
            "DealershipsTransferList-list_noBorder":
              !selectedDealerships.length,
          })}
        >
          {selectedDealerships.map((value) => {
            return (
              <ListItem
                key={value.id}
                className="DealershipsTransferList-listItem"
              >
                <div>{value.name}</div>
                <button onClick={() => toggleDealership(value)}>
                  <Svg w="10" h="10" i="close" />
                </button>
              </ListItem>
            );
          })}
        </List>
      </>
    );
  };

  return (
    <div className="DealershipsTransferList">
      <Grid container justifyContent="space-between" alignItems="flex-top">
        <Grid item className="DealershipsTransferList-listContainer">
          {createLeftList()}
        </Grid>
        <Grid item className="DealershipsTransferList-listContainer">
          {createRightList()}
        </Grid>
      </Grid>
    </div>
  );
};

export default DealershipsTransferList;
