import {styled} from "@mui/material";
import TableCell, {tableCellClasses} from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import TableHead from "@mui/material/TableHead";
import InactiveSorting from '../../../../assets/img/sorting/Sorting inactive.svg'
import AscSorting from '../../../../assets/img/sorting/Sorting ascending.svg'
import DescSorting from '../../../../assets/img/sorting/Sorting descending.svg'
import './StyledTableHeader.scss'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#212230",
        color: theme.palette.common.white,
        fontWeight: 'bold',
        fontSize: '12px',
        fontFamily: window.location.hostname.includes("crmaiva") ? "HKGrotesk" : "Open Sans",
        textTransform: window.location.hostname.includes("crmaiva") ? "uppercase" : "capitalize",
        border: '1px solid',
        borderColor: '#EBEBEB'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: '14px',
        fontFamily: window.location.hostname.includes("crmaiva") ? "HKGrotesk" : "Open Sans",
        border: '1px solid',
        borderColor: '#EBEBEB',
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&': {
        backgroundColor: 'white',
        height: '56px !important',
    }
}));


const StyledTableHeader = ({headers, onSortRequest, activeSortingIndex, ascSortingDirection}) => {
    return <TableHead>
        <StyledTableRow>
            {headers.map((header, index) => {
                return <StyledTableCell key={header}>{header}
                    <img className="sortingButton"
                         src={index === activeSortingIndex ? (ascSortingDirection ? AscSorting : DescSorting) : InactiveSorting}
                         alt={""}
                         onClick={() => onSortRequest(index)}
                    />
                </StyledTableCell>
            })}

        </StyledTableRow>
    </TableHead>
}

export default StyledTableHeader;
