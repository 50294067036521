import * as React from "react";
// import { styled } from '@mui/material/styles';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Box, CircularProgress, Paper, styled } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import useData from "../../../hook/useData";
import { formatToDateString } from "../../../utils/formatDate";
import {
  chooseConversationsEndpoint,
  chooseConversationsMetricType,
  chooseConversationsPersonId,
  chooseConversationsSubMetricType,
} from "../../../store/actions/conversationActions";
import { useHistory } from "react-router-dom";
import StyledTableHeader from "./StyledTableHeader/StyledTableHeader";
import { useState } from "react";
import "./DashboardSale.scss";
import ErrorTriangle from "../../../assets/img/sorting/triangle-exclamation-solid (1).svg";
import Loader from "../../Loader/Loader";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontWeight: "bold",
    fontSize: "12px",
    fontFamily: window.location.hostname.includes("crmaiva") ? "HKGrotesk" : "Open Sans",
    textTransform: window.location.hostname.includes("crmaiva") ? "uppercase" : "capitalize",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "14px",
    fontFamily: window.location.hostname.includes("crmaiva") ? "HKGrotesk" : "Open Sans",
    border: "1px solid",
    borderColor: "#EBEBEB",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&": {
    backgroundColor: "white",
    height: "56px !important",
  },
}));

const indexToColumnMapping = {
  0: "FullName",
  1: "negative",
  2: "positive",
  3: "positive_rate",
};

const DashboardLeadSale = () => {
  const headers = [
    "Sales Rep",
    "Handoffs Awaiting Sales Response",
    "Handoffs Worked",
    "Success Rate",
  ];
  const dispatch = useDispatch();
  const history = useHistory();

  const dateRange = useSelector((state) => state.date);
  const accountId = useSelector(
    (state) => state.user.auth?.data?.user_info?.DealerId
  );

  const [activeSortingIndex, setActiveSortingIndex] = useState(0);
  const [ascSortingDirection, setAscSortingDirection] = useState(true);

  const [total, setTotal] = useState({
    FullName: "Total",
    negative: { value: 0 },
    positive: { value: 0 },
    positive_rate: 0,
  });

  const { data, status, error, statusCode } = useData(
    "salespersonPerformance",
    {
      start_date: formatToDateString(dateRange.startDate),
      end_date: formatToDateString(dateRange.endDate),
    },
    [dateRange, accountId]
  );

  React.useEffect(() => {
    if (!!data?.performance.length) {
      const totalObj = data?.performance.reduce(
        ({ negative, positive }, el) => {
          const negativeLocal = negative.value + el.negative.value;
          const positiveLocal = positive.value + el.positive.value;
          return {
            FullName: "Total",
            negative: { ...negative, value: negativeLocal },
            positive: { ...positive, value: positiveLocal },
            positive_rate: Math.floor(
              (positiveLocal / (negativeLocal + positiveLocal)) * 100
            ),
          };
        },
        {
          FullName: "Total",
          negative: { value: 0 },
          positive: { value: 0 },
          positive_rate: 0,
        }
      );
      setTotal(totalObj);
    }
  }, [data?.performance?.length, accountId]);

  const handleOpenSourceLeads = (endpoint, metric, sub_metric, personId) => {
    dispatch(chooseConversationsEndpoint(endpoint));
    dispatch(chooseConversationsMetricType(metric));
    dispatch(chooseConversationsSubMetricType(sub_metric));
    dispatch(chooseConversationsPersonId(personId));
    history.push("conversations");
  };

  const compareFunction = (a, b, columnName, columnIndex) => {
    if (columnIndex === 0) {
      if (a[columnName].toUpperCase() < b[columnName].toUpperCase()) {
        return ascSortingDirection ? -1 : 1;
      }
      if (a[columnName].toUpperCase() > b[columnName].toUpperCase()) {
        return ascSortingDirection ? 1 : -1;
      }
      return 0;
    } else {
      if (a[columnName] < b[columnName]) {
        return ascSortingDirection ? -1 : 1;
      }
      if (a[columnName] > b[columnName]) {
        return ascSortingDirection ? 1 : -1;
      }
      return 0;
    }
  };

  const handleRequestSort = (newActiveSortingIndex) => {
    if (activeSortingIndex === newActiveSortingIndex) {
      setAscSortingDirection((prevState) => !prevState);
    } else {
      setAscSortingDirection(true);
      setActiveSortingIndex(newActiveSortingIndex);
    }
  };

  data?.performance?.sort((a, b) =>
    compareFunction(
      a,
      b,
      indexToColumnMapping[activeSortingIndex],
      activeSortingIndex
    )
  );

  //console.log(data?.performance);

  if (status === "pending") {
    return (
      <TableContainer
        component={Paper}
        className="tableContainer"
      >
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <StyledTableHeader
            headers={headers}
            onSortRequest={handleRequestSort}
            activeSortingIndex={activeSortingIndex}
            ascSortingDirection={ascSortingDirection}
          />
        </Table>
        <div className="saleTableLoader">
          <Loader />
        </div>
      </TableContainer>
    );
  }

  if (
    status === "error" ||
    (statusCode !== undefined && statusCode !== 200) ||
    status === "init"
  ) {
    return (
      <TableContainer
        component={Paper}
        className={"tableContainer"}
      >
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <StyledTableHeader
            headers={headers}
            onSortRequest={handleRequestSort}
            activeSortingIndex={activeSortingIndex}
            ascSortingDirection={ascSortingDirection}
          />
        </Table>
        {
          <div className="saleTableError">
            <img className="ErrorImage" src={ErrorTriangle} alt={"error"} />
            <p className="ErrorMessage">
              There is a problem displaying these data
            </p>
          </div>
        }
      </TableContainer>
    );
  }

  return (
    <div>
      <TableContainer
        component={Paper}
        className={"tableContainer"}
      >
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <StyledTableHeader
            headers={headers}
            onSortRequest={handleRequestSort}
            activeSortingIndex={activeSortingIndex}
            ascSortingDirection={ascSortingDirection}
          />
          {!!data?.performance.length && (
            <TableBody>
              {data?.performance &&
                [...data?.performance, total]?.map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell
                      align="left"
                      className={`${
                        row?.FullName === "Total" && "totalColumn"
                      }`}
                    >
                      {row?.FullName}
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      className={`${
                        row?.FullName === "Total"
                          ? "totalColumn"
                          : "sourceColumn"
                      }`}
                      onClick={() =>
                        row?.FullName !== "Total" &&
                        row?.negative?.value !== 0 &&
                        handleOpenSourceLeads(
                          row?.negative?.redirect?.endpoint,
                          row?.negative?.redirect?.metric,
                          row?.negative?.redirect?.sub_metric,
                          row?.negative?.redirect?.salesperson_id
                        )
                      }
                    >
                      {row?.negative?.value}
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      className={`${
                        row?.FullName === "Total"
                          ? "totalColumn"
                          : "sourceColumn"
                      }`}
                      onClick={() =>
                        row?.FullName !== "Total" &&
                        row?.positive?.value !== 0 &&
                        handleOpenSourceLeads(
                          row?.positive?.redirect?.endpoint,
                          row?.positive?.redirect?.metric,
                          row?.positive?.redirect?.sub_metric,
                          row?.positive?.redirect?.salesperson_id
                        )
                      }
                    >
                      {row?.positive?.value}
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      className={`${
                        row?.FullName === "Total" && "totalColumn"
                      }`}
                    >
                      {row?.positive_rate}%
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          )}
          {!data?.performance.length && status !== "pending" && (
            <div className="dashboardSaleNoData">
              <p>There is no data available</p>
            </div>
          )}
        </Table>
      </TableContainer>
    </div>
  );
};

export default DashboardLeadSale;
