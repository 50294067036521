import "./AppSwitch.scss";
import ExternalLinkImg from "../../assets/img/external-link.png";

const AppSwitch = () => {
  const marketing_ai_link = process.env['REACT_APP_MARKETING_AI_LINK'];

  return (
    <div className="AppSwitch">
      <a href={marketing_ai_link} className={"appSwitchLink"} target="_blank">
        Go to Marketing AI
        <img src={ExternalLinkImg} className={"externalLinkImage"}/>
      </a>
    </div>
  );
};

export default AppSwitch;
